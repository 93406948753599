export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {

    title: 'Prontuários',
    route: 'list-prontuarios',
    icon: 'UserIcon',
  },
  {
    title: 'Agendamentos',
    icon: 'CalendarIcon',
    children: [

      {
        title: 'Sala de espera',
        route: 'list-agendamentos',
      },
      {
        title: 'Calendário',
        route: 'CalendarioAgendamento',
      },

    ],
  },
  {
    title: 'Cadastros',
    icon: 'PlusSquareIcon',
    children: [
      // {
      //   title: 'Atestados',
      //   route: 'list-atestados',
      // },
      {
        title: 'CIDS',
        route: 'list-cids',
      },
      {
        title: 'Convênios',
        route: 'list-convenios',
      },
      {
        title: 'Empresas',
        route: 'list-empresas',
      },
      {
        title: 'Especialidades',
        route: 'list-especialidades',
      },
      // {
      //   title: 'Exames',
      //   route: 'list-exames',
      // },
      {
        title: 'Laboratórios',
        route: 'list-laboratorios',
      },
      // {
      //   title: 'Laudos',
      //   route: 'list-laudos',
      // },
      {
        title: 'Medicamentos',
        route: 'list-medicamentos',
      },
      {
        title: 'Grade Horária',
        route: 'list-medico_horarios',
      },
      {
        title: 'Pacientes',
        route: 'list-pacientes',
      },
      {
        title: 'Planos',
        route: 'list-planos',
      },

      // {
      //   title: 'Prescrições',
      //   route: 'list-prescricoes',
      // },
      {
        title: 'Principios Ativos',
        route: 'list-principios_ativos',
      },
      {
        title: 'Procedimentos',
        route: 'list-procedimentos',
      },
      {
        title: 'Profissionais',
        route: 'list-profissionais',
      },

      {
        title: 'Tipo Conselhos',
        route: 'list-tipo_conselhos',
      },
      {
        title: 'Tipo Profissionais',
        route: 'list-tipo_profissionais',
      },
      {
        title: 'Tipo Receitas',
        route: 'list-tipo_receitas',
      },
      {
        title: 'Usuários',
        route: 'list-users',
      },
      {
        title: 'Prioridades',
        route: 'list-prioridades'
      },
      {
        title: 'Checklist',
        route: 'list-checklists'
      },
      {
        title: 'Itens Checklist',
        route: 'list-itens-checklists'
      }
    ],
  },
  {
    title: 'Solicitações',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Exames',
        route: 'list-solicitacao_exames',
      },
    ],
  },
]
